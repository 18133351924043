
import { FormValidations } from "@/mixins/form-validations";
import { UserValidations } from "@/mixins/user-validations";
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import VueCountryCode from "vue-country-code";
import { EditorImages } from "@/mixins/editor-images";
import { FileCheck } from "@/mixins/file-check";
import { Company } from "@/models/company.interface";
import LocaleSwitcher from "@/components/General/LocaleSwitcher.vue";
import Captcha from "@/components/Input/Captcha.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    VueEditor,
    VueCountryCode,
    LocaleSwitcher,
    Captcha,
  },
})
export default class ProjectApplication extends mixins(
  FormValidations,
  Navigation,
  CustomDate,
  EditorImages,
  FileCheck,
  UserValidations,
  StyleCheck
) {
  $refs!: {
    applicationForm: HTMLFormElement;
  };
  hcaptcha_done = false;
  hcaptcha_error = false;
  loading = false;
  showErrorDescription = false;
  showSuccessApplication = false;
  applicationEnded = false;
  loader = false;
  application = {
    name: "",
    email: "",
    phone: "",
    description: "",
    amount: 0,
    lastUpdate: "",
    dialCode: "",
    iso2: "",
    formatedPhone: "",
    userLogged: false,
  };

  private async userLoggedValidation() {
    if (!this.userLogged) {
      await this.$store.dispatch("application/securitySettingsForApplication");
    }
  }

  private async addNewApplication() {
    await this.userLoggedValidation();
    this.$store.dispatch("application/resetApplication", true);
    this.loader = false;
    this.showSuccessApplication = false;
    this.applicationEnded = false;
    this.setValues();
    this.hcaptcha_done = false;
    this.$nextTick(() => {
      this.showErrorDescription = false;
      this.hcaptcha_error = false;
      this.loading = false;
    });
  }

  private async prevForm() {
    await this.userLoggedValidation();
    this.loader = false;
    this.showSuccessApplication = false;
    this.applicationEnded = false;
    this.hcaptcha_done = false;
    this.$nextTick(() => {
      this.hcaptcha_error = false;
      this.loading = false;
    });
  }

  private setValues() {
    this.application.userLogged = !this.userLogged ? false : true;
    this.application.name = this.applicationState.name;
    this.application.email = this.applicationState.email;
    this.application.phone = this.applicationState.phone;
    this.application.description = this.applicationState.description;
    this.application.amount = this.applicationState.amount;
    this.application.lastUpdate = this.applicationState.lastUpdate;
    this.application.dialCode = this.applicationState.dialCode;
    this.application.iso2 = this.applicationState.iso2;
    this.application.formatedPhone = this.applicationState.formatedPhone;
  }

  private async created() {
    this.setValues();
    await this.userLoggedValidation();
  }

  @Watch("application.name")
  @Watch("application.email")
  @Watch("application.phone")
  @Watch("application.dialCode")
  @Watch("application.iso2")
  @Watch("application.formatedPhone")
  @Watch("application.description")
  @Watch("application.amount")
  private updatesOnApplicationHandler() {
    if (
      this.application.name !== this.applicationState.name ||
      this.application.email !== this.applicationState.email ||
      this.application.phone !== this.applicationState.phone ||
      this.application.formatedPhone !== this.applicationState.formatedPhone ||
      this.application.dialCode !== this.applicationState.dialCode ||
      this.application.iso2 !== this.applicationState.iso2 ||
      this.application.description !== this.applicationState.description ||
      this.application.amount !== this.applicationState.amount
    ) {
      if (this.application.name == null) this.application.name = "";
      if (this.application.email == null) this.application.email = "";

      this.application.lastUpdate = this.currentDate();
      this.$store.dispatch("application/setApplicationInfo", this.application);
    }
  }

  private get applicationState(): any {
    return this.$store.getters["application/getApplicationState"];
  }

  private get companyInfo(): Company {
    return this.$store.getters["system/getCompany"];
  }

  private selectedCountry(country: any): void {
    this.application.dialCode = country.dialCode;
    this.application.iso2 = country.iso2;
    this.application.formatedPhone =
      "+" +
      this.application.dialCode +
      " " +
      (this.application.phone == null ? "" : this.application.phone) +
      " (" +
      this.application.iso2 +
      ")";
  }

  private async sendApplication() {
    if (
      this.$refs.applicationForm.validate() &&
      this.application.description !== ""
    ) {
      this.loader = true;
      let application = { ...this.application };
      application.phone = this.application.formatedPhone;
      await this.$store
        .dispatch("application/createApplication", application)
        .then(async () => {
          this.showSuccessApplication = true;
          if (!this.userLogged)
            await this.$store.dispatch("authentication/logOut");
        })
        .catch(() => {
          this.showSuccessApplication = false;
        });

      this.loader = false;
      this.applicationEnded = true;
    } else {
      if (this.application.description == "") {
        this.showErrorDescription = true;
      }

      if (!this.hcaptcha_done) this.hcaptcha_error = true;
    }
  }

  @Watch("hcaptcha_done")
  checkhCaptcha(value): void {
    if (value) {
      this.hcaptcha_error = false;
    } else {
      this.hcaptcha_error = true;
    }
  }

  @Watch("application.description")
  checkDescription(): void {
    if (this.application.description != "") {
      this.showErrorDescription = false;
    } else {
      this.showErrorDescription = true;
    }
  }

  @Watch("application.phone")
  setApplicationContactPhone(phone: string): void {
    const clearPhone = phone != null ? phone : "";
    this.application.formatedPhone =
      "+" +
      this.application.dialCode +
      " " +
      clearPhone +
      " (" +
      this.application.iso2 +
      ")";
  }
}
