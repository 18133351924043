
import Vue from "vue";
import Component from "vue-class-component";
import { PropSync } from "vue-property-decorator";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { Notification } from "@/models/notification.interface";

@Component({ components: { VueHcaptcha } })
export default class Captcha extends Vue {
  hcaptcha_site_key = process.env.VUE_APP_HCAPTCHA_SITE_KEY;
  @PropSync("captchaDone", { type: Boolean, default: false })
  hcaptcha_done!: boolean;
  @PropSync("loading", { type: Boolean, default: false })
  loadingSync!: boolean;

  $refs!: {
    hCaptcha: HTMLFormElement;
  };

  private async verifyCaptcha(token, eKey) {
    this.loadingSync = true;
    await this.$store
      .dispatch("authentication/validateHCaptcha", {
        token: token,
        eKey: eKey,
      })
      .then((response) => {
        if (response) {
          this.hcaptcha_done = true;
        } else {
          this.hcaptcha_done = false;
          const Error: Notification = {
            message: this.$tc("Views.hCaptchaError2"),
            timeout: "60000",
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
          this.$refs.hCaptcha.reset();
        }
      })
      .catch(() => {
        this.hcaptcha_done = false;
        const Error: Notification = {
          message: this.$tc("Views.hCaptchaError2"),
          timeout: "60000",
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
        this.$refs.hCaptcha.reset();
      });
    this.loadingSync = false;
  }

  private onExpire(): void {
    this.hcaptcha_done = false;
    const Error: Notification = {
      message: this.$tc("Views.hCaptchaError3"),
      timeout: "60000",
      top: true,
      bottom: false,
      left: false,
      right: false,
      currentPath: this.$route.fullPath,
      error: true,
    };

    this.$store.dispatch("notifications/showNotification", Error);
    this.$refs.hCaptcha.reset();
  }

  private onChallengeExpire(): void {
    this.hcaptcha_done = false;
    const Error: Notification = {
      message: this.$tc("Views.hCaptchaError4"),
      timeout: "60000",
      top: true,
      bottom: false,
      left: false,
      right: false,
      currentPath: this.$route.fullPath,
      error: true,
    };

    this.$store.dispatch("notifications/showNotification", Error);
    this.$refs.hCaptcha.reset();
  }

  private onError(): void {
    this.hcaptcha_done = false;
    const Error: Notification = {
      message: this.$tc("Views.hCaptchaError5"),
      timeout: "60000",
      top: true,
      bottom: false,
      left: false,
      right: false,
      currentPath: this.$route.fullPath,
      error: true,
    };

    this.$store.dispatch("notifications/showNotification", Error);
    this.$refs.hCaptcha.reset();
  }
}
